<template>
  <b-modal :visible="show" :hide-header="true" centered id="modal-cotacao" size="lg" :hide-footer="true">
    <div class="float-right"><img @click="hide" class="close" src="img/x-icon.svg" /></div>
    <div class="row text-center">
      <div class="col-sm-12 col-12 mt-2">
        <img class="vw-20 vw-m-60 pt-5 pb-5" src="img/cotacao-especial.svg" />

        <h4 class="title-cotacao mb-4">Perfeito {{ nome }}, temos uma cotação especial personalizada para o seu modelo de bike. Esta
          oferta é exclusiva
          para você. </h4>
        <h5 class="sub-title-cotacao">Gostaria de receber a cotação?</h5>
        <p class="sub-title-cotacao">Modelos de bicicleta com valores superiores a R$50.000,00, são segurados por produtos
          personalizados. Você
          receberá a cotação pelo Whatsapp.</p>

        <a class="btn confirm" target="_blank"
          :href="`https://api.whatsapp.com/send?phone=${config.WHATSAPP_NUMERO}&text=Olá, meu nome é ${nome} e preciso de atendimento. Pois o valor da minha bike é R$ ${formatacaoMoeda2(valorBike)}`">
          <span class="text">Receber cotação</span>
        </a>
      </div>
    </div>
  </b-modal>
</template>
<script>
import config from "../../config";
import formatacaoMoeda2 from "../../helpers/formatacaoMoeda2";
export default {
  props: ["show", "nome", "valorBike"],
  data() {
    return { config, formatacaoMoeda2 };
  },
  methods: {
    hide() {
      this.$emit("hide");
    },
  },
};
</script>

<style>
#modal-cotacao___BV_modal_content_ {
  border-radius: 12px !important;
  padding: 25px;
  padding-top: 15px;
}

.close {
  width: 15px;
  cursor: pointer;
}

.vw-20 {
  width: 20vw;
}

.voltar {
  color: #9a9a9a;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.confirm {
  font-size: 18px;
  padding: 12px 20px 12px 20px;
  border-radius: 12px;
  border: 1px solid #4FA935;
  background: #4FA935;
  color: #fff;
}

.title-cotacao {
  color: #425994;
}

.sub-title-cotacao {
  color: #78849E;
}


@media (max-width: 768px) {
  
  .title-cotacao{
    font-size: 15px !important;
    font-weight: bold;
  }
  .sub-title-cotacao{
    font-size: 14px !important;
  }

  .sub-title {
    font-size: 17px;
  }

  .title {
    font-size: 20px;
  }

  .vw-m-60 {
    width: 30vw;
  }

  #modal-cotacao___BV_modal_content_ {
    padding: 0 !important;
    padding-top: 0 !important;
  }
}
</style>
